/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { SERVER_URL } from '../utils/environment';

class Unsubscribe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      foo: null,
    };
  }
  componentDidMount() {
    const email = this.props.location.search.split('=')[1];
    this.unsubscribe(email);
  }
  unsubscribe = email => {
    axios
      .get(`${SERVER_URL}/api/v2/unsubscribe_emails/unsubscribe?email=${email}`)
      .then(() => {})
      .catch(() => {});
  };

  render() {
    return (
      <div style={{ padding: '40px' }}>
        You have successfully unsubscribed from emails.
      </div>
    );
  }
}

Unsubscribe.propTypes = {
  location: PropTypes.object,
};

Unsubscribe.defaultProps = { location: {} };

export default Unsubscribe;
